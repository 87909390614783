<template>
  <v-row class="mt-4">
    <v-col class="error-banner" cols="10" offset="1">
      <span class="red--text">Une erreur est survenue.</span><br>
      <router-link to="/dossier">Retour à la liste des dossiers</router-link>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ErrorBanner',
}
</script>
<style scoped>
.error-banner {
  background-color: #ffaeb3;
  border: 1px solid red;
  border-radius: 15px;
  padding: 15px;
}

</style>