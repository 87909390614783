<template>
  <v-row justify="center" align="center" no-gutters>
    <v-col cols="12" class="d-flex align-center justify-center">
      <canvas @endStroke="change" :width="width" :height="height" :class="{bordered: bordered}" :ref="setSignature">
      </canvas>
    </v-col>
    <v-col cols="12" class="d-flex justify-center align-center">
      <v-btn color="grey" class="white--text" @click="clean">Effacer</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import SignaturePad from "signature_pad";

export default {
  name: "SignatureArea",
  props: {
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      signature: null,
    }
  },
  methods: {
    setSignature(el) {
      this.signature = new SignaturePad(el);
      this.signature.addEventListener('endStroke', this.change);
    },
    change() {
      this.$emit("input", this.signature.toDataURL());
    },
    clean() {
      this.signature.clear();
    }
  }
}
</script>

<style scoped>
.bordered {
  border: 1px solid gray;
}

</style>