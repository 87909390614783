<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn class="mt-4 white--text" v-on="on" v-bind="attrs" color="orange">
        <v-icon>mdi-calendar</v-icon>
        Modifier la date
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier le montant total</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-2">
        <v-menu v-model="menu_date" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false"
                    :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Date du devis"
                              :value="getFormattedDate(dateDevis)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="dateDevis"></v-date-picker>
                <v-card-actions>
                  <v-btn @click="menu_date = false" text color="primary">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="editing" @click="changeDate" color="primary">Modifier</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {DateUtilMixin} from "@/mixins/DateUtilMixin";

export default {
  name: 'EditDateDevisDialog',
  mixins: [DateUtilMixin],
  props: {
    devis: {},
  },
  data() {
    return {
      dateDevis: null,
      menu_date: false,
      editing: false,
      dialog: false,
    }
  },
  methods: {
    async changeDate() {
      this.editing = true;
      let devis = await this.$store.dispatch("devis/patchDevis", {id: this.devis.id, proposal_date: this.dateDevis})
      this.$emit("reload-devis");
      if (devis !== undefined) {
        this.$store.dispatch("annonce/annonceSuccess", "Devis mis à jour");
      }
      else {
        this.$store.dispatch("annonce/annonceWarning", "Une erreur est survenue, vérifiez si le devis à " +
            "bien été mis à jour.")
      }
      this.editing = false;
      this.dialog = false;
    }
  }
}
</script>