<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn class="mt-4" v-on="on" v-bind="attrs" v-if="devis.type === 'import'" color="primary">
        <v-icon>mdi-currency-eur</v-icon>
        Modifier le montant total
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier le montant total</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-2">
        <v-text-field type="number" step="0.01" label="Montant total" hint="Veuillez insérer le montant du devis importé." persistent-hint v-model="montantTotal"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="editing" @click="changeMontant" color="primary">Modifier</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'EditMontantDevisDialog',
  props: {
    devis: {},
  },
  data() {
    return {
      montantTotal: this.devis?.total || 0.0,
      editing: false,
      dialog: false,
    }
  },
  methods: {
    async changeMontant() {
      this.editing = true;
      let devis = await this.$store.dispatch("devis/patchDevis", {id: this.devis.id, montant_total: this.montantTotal})
      this.$emit("reload-devis");
      if (devis !== undefined) {
        this.$store.dispatch("annonce/annonceSuccess", "Devis mis à jour");
      }
      else {
        this.$store.dispatch("annonce/annonceWarning", "Une erreur est survenue, vérifiez si le devis à " +
            "bien été mis à jour.")
      }
      this.editing = false;
      this.dialog = false;
    }
  }
}
</script>