<template>
  <v-dialog v-model="dialog" max-width="600" class="mt-1">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" color="blue" class="white--text darken-2 mt-4"><v-icon>mdi-currency-eur</v-icon> Signer le devis</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Signer le devis</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <h1 class="text-center">Signer le devis</h1>
        <div class="mt-4"><h3>Signature du maître d'ouvrage</h3>
          <div class="d-flex align-center justify-center">
            <signature-area v-model="signature.maitre_ouvrage"></signature-area>
          </div>
        </div>
        <div class="mt-4"><h3>Signature de l'entreprise</h3>
          <div class="d-flex align-center justify-center">
            <signature-area v-model="signature.entreprise"></signature-area>
          </div>
        </div>
        <div class="mt-4"><h3>Signature de la DEAL</h3>
          <div class="d-flex align-center justify-center">
            <signature-area v-model="signature.deal"></signature-area>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="signDevis" :disabled="loading" color="primary">Signer</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import SignatureArea from "@/components/base/SignatureArea.vue";

export default {
  name: 'SignDevisForm',
  components: {SignatureArea},
  props: {
    devis: {
      type: Object,
      default: () => {},
    }
  },
  mounted() {

  },
  data() {
    return {
      signature: {
        maitre_ouvrage: null,
        entreprise: null,
        deal: null,
        id: this.devis?.id,
        signed: true,
      },
      dialog: false,
      loading: false,
    }
  },
  methods: {
    async signDevis() {
      this.loading = true;
      this.signature.id = this.devis.id;
      let response = await this.$store.dispatch("devis/generateDevis", this.signature);
      this.$emit("reload-devis");
      if (response !== undefined) {
        await this.$store.dispatch("annonce/annonceSuccess", "Devis signé");
      } else {
        await this.$store.dispatch("annonce/annonceWarning", "Une erreur est survenue, vérifiez si le devis à " +
            "bien été mis à jour.")
      }
      this.loading = false;
      this.dialog = false;
    }
  }
}
</script>