<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn class="mt-4 darken-2 white--text" v-on="on" v-bind="attrs" color="blue">
        <v-icon>mdi-currency-eur</v-icon>
        Modifier le fichier
      </v-btn>
      <div>
        <v-btn :disabled="editing" class="mt-4 darken-2 white--text" @click="generateDevis" color="blue">
          <v-icon>mdi-currency-eur</v-icon>
          Générer le devis
        </v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </div>
    </template>
    <v-card elevation="0">
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier le fichier</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-2">
        <v-file-input v-model="fileInput" label="Fichier du devis"></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="editing" @click="changeFile" color="primary">Modifier</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'EditMontantDevisDialog',
  components: {},
  props: {
    devis: {},
  },
  data() {
    return {
      fileInput: null,
      editing: false,
      dialog: false,
    }
  },
  methods: {
    async changeFile() {
      this.editing = true;
      let devis = await this.$store.dispatch("devis/patchDevisFile", {id: this.devis.id, proposal_file: this.fileInput})
      this.$emit("reload-devis");
      if (devis !== undefined) {
        this.$store.dispatch("annonce/annonceSuccess", "Devis mis à jour");
      } else {
        this.$store.dispatch("annonce/annonceWarning", "Une erreur est survenue, vérifiez si le devis à " +
            "bien été mis à jour.")
      }
      this.editing = false;
      this.dialog = false;
    },
    async generateDevis() {
      this.editing = true;
      let response = await this.$store.dispatch("devis/generateDevis", {id: this.devis.id});
      this.$emit("reload-devis");
      if (response !== undefined) {
        this.$store.dispatch("annonce/annonceSuccess", "Devis généré");
      } else {
        this.$store.dispatch("annonce/annonceWarning", "Une erreur est survenue, vérifiez si le devis à " +
            "bien été mis à jour.")
      }
      this.editing = false;
      this.dialog = false;
    }
  }
}
</script>