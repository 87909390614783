<template>
  <v-row class="mt-4" justify="center" align="center">
    <v-col cols="1">
      <v-progress-circular color="primary"  indeterminate></v-progress-circular>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'LoadingBanner',
}
</script>
