<template>
  <v-dialog v-model="showDialog" max-width="800">
    <template v-slot:activator="{on, attrs}">
      <v-btn icon color="primary" v-on="on" v-bind="attrs">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier l'opération</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select item-text="name" :disabled="!isIntern" item-value="id" :items="categories" v-model="operationModel.category" label="Catégorie"></v-select>
        <v-text-field label="Code" :disabled="!isIntern" v-model="operationModel.code"></v-text-field>
        <v-text-field label="Description" :disabled="!isIntern"  v-model="operationModel.description"></v-text-field>
        <v-text-field :disabled="!isIntern" type="number" step="0.01" label="Prix" v-model="operationModel.price"></v-text-field>
        <v-text-field type="number" step="1" label="Quantité" v-model="operationModel.quantity"></v-text-field>
        <v-text-field label="Observations" v-model="operationModel.observations"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn :color="loading ? 'grey':'primary'" class="white--text" @click="patchOperation">Modifier</v-btn>
        <v-progress-circular v-if="loading" color="primary" indeterminate></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: 'EditDevisOperation',
  mixins: [AuthMixin],
  props: {
    operation: Object,
  },
  data() {
    let operationModel = JSON.parse(JSON.stringify(this.operation));
    if(typeof operationModel.category === "object") {
      operationModel.category = operationModel.category.id;
    }
    return {
      loading: false,
      operationModel: operationModel,
      categoryModel: null,
      devis: null,
      showDialog: false,
    }
  },
  methods: {
    async patchOperation() {
      this.loading = true;
      if(!this.isIntern) {
        delete this.operationModel.price;
        delete this.operationModel.code;
        delete this.operationModel.description;
        delete this.operationModel.category;

      }
      let newOperation = await this.$store.dispatch("operations/patchOperation", this.operationModel);
      if (newOperation !== null) {
        this.$emit("reload-devis");
        this.$store.dispatch("annonce/annonceSuccess", "Opération mise à jour");
      }
      this.loading = false;
      this.showDialog = false;
    },
  },
  computed: {
    referentiel() {
    return this.$store.getters["referentiel/referentiel"];
  },
  categories() {
    return [{id: null, name: "Toutes les catégories"}, ...this.$store.getters["category/categories"]];
  },
  filtered_referentiel() {
      return this.referentiel.filter((item) => {
        if (this.categoryModel !== null) {
          return item.category?.id === this.categoryModel;
        } else {
          return true;
        }
      });
    },
  }
}
</script>
