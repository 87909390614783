<template>
  <v-dialog max-width="600" v-model="dialog">
    <template v-slot:activator="{on, attrs}">
      <v-btn  v-bind="attrs" v-on="on" color="red" class="white--text mt-4">
        <v-icon>mdi-pencil</v-icon>
        Modifier le nom
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier le nom du devis</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field label="Nom du devis" :rules="[value => value != null || 'Nom requis.']" v-model="devisName"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="editing" @click="changeName">Modifier</v-btn>
        <v-progress-circular v-if="editing" indeterminate color="primary"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'EditDevisName',
  props: {
    devis: Object,
  },
  data() {
    return {
      devisName: this.devis.name ?? "",
      dialog: false,
      editing: false,
    }
  },
  methods: {
    async changeName() {
      this.editing = true;
      let devis = await this.$store.dispatch("devis/patchDevis", {id: this.devis.id, name: this.devisName})
      this.$emit("reload-devis");
      if (devis !== undefined) {
        this.$store.dispatch("annonce/annonceSuccess", "Devis mis à jour");
      }
      else {
        this.$store.dispatch("annonce/annonceWarning", "Une erreur est survenue, vérifiez si le devis à " +
            "bien été mis à jour.")
      }
      this.editing = false;
      this.dialog = false;
    }
  }
}
</script>